import Vue from 'vue'
import Vuetable from 'vuetable-2'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue';
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin'
import $ from 'jquery'
import axios from "axios";
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)


export default {
    components: {
        Vuetable,
        VuetablePagination,
        VuetableFieldCheckbox,
        VuetableFieldMixin
    },
    data: function() {
        return {
            pageTitle: this.$t('articles_famille.famille_article_liste'),

            vuetable: {
                moreParams: {},
                fields: [
                    // {
                    //     name: VuetableFieldCheckbox,
                    //     titleClass: 'text-center',
                    //     dataClass: 'text-center'
                    // },
                    {
                        name: 'nom_famille',
                        title: this.$t('articles_famille.famille_article'),
                        sortField: 'nom_famille',
                        dataClass: 'text-left'
                    },
                    {
                        name: 'prix_revient',
                        title: this.$t('articles.prix_revient'),
                        sortField: 'prix_revient',
                        dataClass: 'text-right',
                    },
                    {
                        name: 'marge_pourcentage',
                        title: this.$t('articles.marge_pourcent'),
                        sortField: 'marge_pourcentage',
                        dataClass: 'text-right',
                        formatter(value) {
                            return (new Intl.NumberFormat().format(value)) + ' %'
                        }
                    },
                    {
                        name: 'marge_monetaire',
                        title: 'Marge',
                        sortField: 'marge_monetaire',
                        dataClass: 'text-right text-bold'
                    },
                    {
                        name: 'pu_vente',
                        title: 'P.U Vente',
                        sortField: 'pu_vente',
                        dataClass: 'text-right text-bold text-danger'
                    },
                    {
                        name: 'actions',
                        width: "80px",
                        dataClass: "text-center"
                    }

                ],
                sortOrder: [
                    { field: 'nom_famille', direction: 'asc' }
                ],
                css: {
                    table: {
                        tableClass: 'table table-striped table-bordered table-hovered',
                        loadingClass: 'loading',
                        ascendingIcon: 'fas fa-arrow-up fa-sm',
                        descendingIcon: 'fas fa-arrow-down fa-sm',
                        handleIcon: 'fas fa-bars fa-sm',
                    },
                    pagination: {
                        infoClass: 'pull-left ',
                        wrapperClass: 'vuetable-pagination text-center',
                        activeClass: 'btn-secondary',
                        disabledClass: 'disabled',
                        pageClass: 'btn btn-border',
                        linkClass: 'btn btn-border',
                        icons: {
                            first: '',
                            prev: '',
                            next: '',
                            last: '',
                        },
                    }
                },
            },
            exportexcel: {
                json_fields: {
                    'Famille d\'article': 'nom_famille'
                },
                json_data: [],
                json_meta: [
                    [{
                        'key': 'charset',
                        'value': 'utf-8'
                    }]
                ],
            },
            motCle: "",
            crudform: {
                id: "",
                nom_famille: "",
                prix_revient: "",
                marge_pourcentage: "",
                marge_monetaire: "",
                tva: 0,
                pu_vente: ""
            },
            crudmodaltitle: this.$t('articles_famille.modal.ajouter_famille'),


        }
    },
    methods: {
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData)
        },
        onChangePage(page) {
            this.$refs.vuetable.changePage(page)
        },
        editRow(rowData) {
            //alert("You clicked edit on" + JSON.stringify(rowData))
            axios.get(this.BASE_URL + "/articlesfamille/get/" + rowData.id).then(response => {
                //console.log(response.data[0]);
                this.crudform = response.data[0];
            });
            this.crudmodaltitle = "Editer une famille d'article";
            this.openModal();
        },
        deleteRow(rowData) {
            //alert("You clicked delete on" + JSON.stringify(rowData));
            var that = this;
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment supprimer cette ligne?', {
                    title: 'Confirmation',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Supprimer',
                    cancelTitle: 'Annuler',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        axios.post(that.BASE_URL + "/articlesfamille/delete/" + rowData.id).then(function(response) {
                            console.log(response);
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log(response.data.message);
                                that.setFilter();
                                Vue.$toast.open({
                                    message: response.data.message,
                                    type: 'success',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                })

        },
        onLoading() {
            console.log('loading... show your spinner here')
        },
        onLoaded() {
            console.log('loaded! .. hide your spinner here');
        },
        setFilter() {
            this.vuetable.moreParams.filter = this.motCle;
            this.vuetable.moreParams.criteriacol = this.criteriacol;
            Vue.nextTick(() => this.$refs.vuetable.refresh());
        },
        resetFilter() {
            this.motCle = "";
            this.criteriacol = "";
            this.vuetable.moreParams.filter = this.motCle;
            Vue.nextTick(() => this.$refs.vuetable.refresh());
        },
        onExport() {

            this.exportexcel.json_data = this.$refs.vuetable.$data.tableData;
            $("#excel-download").trigger("click");
        },
        openModal() {
            this.$bvModal.show("crudmodal");
        },
        closeModal() {
            this.$bvModal.hide("crudmodal");
        },
        addRow() {
            this.crudmodaltitle = "Ajouter une famille d'article";
            this.crudform = {
                id: "",
                nom_famille: "",
                prix_revient: "",
                marge_pourcentage: "",
                marge_monetaire: "",
                tva: 0,
                pu_vente: ""
            }
            this.openModal();
        },
        onSubmit() {
            var that = this;
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment continuer l\'enregistrement?', {
                    title: 'Confirmation',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: 'Continuer',
                    cancelTitle: 'Annuler',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        var link = that.crudform.id == '' ? that.BASE_URL + "/articlesfamille/addaction" : that.BASE_URL + "/articlesfamille/editaction";
                        axios.post(link, $("#formulaire").serialize()).then(function(response) {
                            console.log(response);
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log("Enregistrement effectué avec succès!");
                                that.closeModal();
                                that.setFilter();
                                Vue.$toast.open({
                                    message: 'Enregistrement effectué avec succès!',
                                    type: 'success',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                });
        },
        onChangeprixrevient: function() {
            var newvalue = this.crudform.prix_revient;
            if (newvalue == '' || newvalue == null) {
                newvalue = 0;
            }
            if (parseFloat(this.crudform.marge_pourcentage) > 0) {
                this.crudform.marge_monetaire = parseFloat(newvalue) * parseFloat(this.crudform.marge_pourcentage) / 100;
            }

            if (this.crudform.marge_monetaire == '' || this.crudform.marge_monetaire == null) {
                this.crudform.marge_monetaire = 0;
            }
            console.log(newvalue);
            this.crudform.pu_vente = parseFloat(this.crudform.prix_revient) + parseFloat(this.crudform.marge_monetaire);
        },
        onChangemargepourcentage: function() {
            var newvalue = this.crudform.marge_pourcentage;
            if (newvalue == '' || newvalue == null) {
                newvalue = 0;
            }
            if (this.crudform.prix_revient == '' || this.crudform.prix_revient == null) {
                this.crudform.prix_revient = 0;
            }
            this.crudform.marge_monetaire = parseFloat(this.crudform.prix_revient) * parseFloat(newvalue) / 100;
            this.crudform.pu_vente = parseFloat(this.crudform.prix_revient) + this.crudform.marge_monetaire;
        },
        onChangemargemonetaire: function() {
            var newvalue = this.crudform.marge_monetaire;
            if (newvalue == '' || newvalue == null) {
                newvalue = 0;
            }
            if (this.crudform.prix_revient == '' || this.crudform.prix_revient == null) {
                this.crudform.prix_revient = 0;
            }
            if (this.crudform.marge_monetaire == '' || this.crudform.marge_monetaire == null) {
                this.crudform.marge_monetaire = 0;
            }
            if (parseFloat(this.crudform.prix_revient) > 0) {
                this.crudform.marge_pourcentage = parseFloat(newvalue) * 100 / parseFloat(this.crudform.prix_revient);
            }
            this.crudform.pu_vente = parseFloat(this.crudform.prix_revient) + parseFloat(newvalue);
        },
        onChangepuvente: function() {
            var newvalue = this.crudform.pu_vente;
            if (newvalue == '' || newvalue == null) {
                newvalue = 0;
            }
            if (parseFloat(this.crudform.prix_revient) > 0) {
                this.crudform.marge_monetaire = parseFloat(newvalue) - parseFloat(this.crudform.prix_revient);
                this.crudform.marge_pourcentage = parseFloat(this.crudform.marge_monetaire) * 100 / parseFloat(this.crudform.prix_revient);
            }
        }
    },
    computed: {
        /*httpOptions(){
            return {headers: {'Authorization': "my-token"}} //table props -> :http-options="httpOptions"
        },*/
    },
    created: function() {
        this.$emit('change-page', this.pageTitle);

        this.motCle = "";
        this.criteriacol = "";
    },
    mounted: function() {

        console.log(process);
        console.log(process.env);
        console.log(process.env.STRIPE_TOKEN);

    }
}